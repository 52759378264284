import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IConfig, Toggles } from '../../utils/config/config.interface';
import { RootState } from '../store';
import { wizardGetState } from '../api/wizard/wizardGetState';
import { DeliveryType } from '@teleport/schemas-protobuf';
import { ITranslation, Language } from '../../utils/i18n/i18n.interface';

interface IAppConfig {
  portApiUrl: string;
  hubApiUrl: string;
  fsApiUrl: string;
  billingApiUrl: string;
  portCodename: string;
  accentColor: string;
  deliveryType: DeliveryType;
  language: string;
  hubBotUsername: string;
  portBotUsername: string;
  toggles: Record<Toggles, boolean>;
  enableSubcategories: boolean;
}

interface IConfigState {
  config: IAppConfig;
  translation: Record<Language, ITranslation>;
}

const initialState: IConfigState = {
  config: {
    portApiUrl: '',
    hubApiUrl: '',
    fsApiUrl: '',
    billingApiUrl: '',
    portCodename: '',
    accentColor: '',
    deliveryType: DeliveryType.UNSPECIFIED,
    language: '',
    hubBotUsername: '',
    portBotUsername: '',
    toggles: {
      [Toggles.loyaltyEnabled]: false,
      [Toggles.modifiersEnabled]: false,
      [Toggles.multipleStoreEnabled]: false,
      [Toggles.newsletterEnabled]: false,
      [Toggles.promoCodeEnabled]: false,
      [Toggles.statisticsEnabled]: false,
    },
    enableSubcategories: false,
  },
  translation: {
    [Language.en]: undefined,
    [Language.ru]: undefined,
  },
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<IConfig>) => {
      state.config = { ...state.config, ...action.payload };
      window.localStorage.setItem('hubBotUsername', action.payload.hubBotUsername);
    },
    setTranslation: (
      state,
      action: PayloadAction<{ translation: ITranslation; language: Language }>,
    ) => {
      const { translation, language } = action.payload;
      state.config.language = language;
      state.translation[language] = translation;
    },
    setDeliveryType: (state, action: PayloadAction<DeliveryType>) => {
      state.config.deliveryType = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(wizardGetState.fulfilled, (state, action) => {
      state.config.deliveryType = action.payload.wizardData.deliveryType;
      state.config.portApiUrl = action.payload.portApiUrl;
      state.config.portCodename = action.payload.portCodename;
    });
  },
});

export const { setConfig, setTranslation, setDeliveryType } = configSlice.actions;

export const configState = (state: RootState) => state[configSlice.name];

export const togglesState = (state: RootState) => state[configSlice.name].config.toggles;
